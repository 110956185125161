import React, { useState } from "react"
import axios from "axios"

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()

  let [classFormSubmited, changeClass] = useState("")
  let [btnDisabled, toggleBtn] = useState(false)

  const handleOnSubmit = e => {
    e.preventDefault()
    toggleBtn((btnDisabled = true))
    const form = e.target
    axios({
      method: "post",
      url: "https://getform.io/f/d10cd23d-ee2d-4750-ba0c-934e07a2b836",
      data: new FormData(form),
    })
      .then(r => {
        changeClass((classFormSubmited = "submited"))
        toggleBtn((btnDisabled = false))
      })
      .catch(r => {
        toggleBtn((btnDisabled = false))
      })
  }
  return (
    <div
      id="contact-me"
      className="footer container max-w-screen-lg mt-12 px-4"
    >
      <hr className="footer__divider w-3/4 bg-gray-700 mx-auto {}" />
      <h5 className="footer__title">Contact Me</h5>
      <form
        className={`footer__form ${classFormSubmited}`}
        onSubmit={handleOnSubmit}
      >
        <div className="footer__form-flex">
          <input
            className="footer__form-name"
            type="text"
            name="name"
            placeholder="Name"
            required
          />
          <input
            className="footer__form-email"
            type="email"
            name="email"
            placeholder="Email"
            required
          />
        </div>

        <textarea
          name="message"
          id=""
          rows="7"
          placeholder="Message"
          required
        ></textarea>
        <div className="footer__form-submit-wrapper">
          <button
            className="footer__form-submit home__btn btn btn--full "
            type="submit"
            disabled={btnDisabled}
          >
            Send
          </button>
          {classFormSubmited && (
            <div className="footer__thanks flex items-center text-center justify-center text-gray-100">
              "Thanks for contacting me, I'll reach you soon."
            </div>
          )}
          <div className="footer__linkedin">
            <a
              href="https://www.linkedin.com/in/jonnyacevedo"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906079/portfolio/linkedin-icon_zwu4m7.svg"
                alt="Linkedin Icon"
              />
            </a>
          </div>
        </div>
      </form>
      <div className="footer__rights text-center text-gray-600 text-sm pt-2">
        Copyrights © {year}. All rights reserved.
      </div>
    </div>
  )
}

export default Footer
