import { Link } from "gatsby"
import React, { useState } from "react"

const Header = () => {
  let [isMenuOpen, toggleMenu] = useState(false)
  const onMenuToggle = () => {
    toggleMenu((isMenuOpen = !isMenuOpen))
  }
  return (
    <header
      className={`header sticky top-0 z-10 pt-2 pb-3 bg-gray-900 h-12 shadow-xl ${
        isMenuOpen ? "menu-open" : ""
      }`}
    >
      <div className="header__container container max-w-screen-lg px-4 flex justify-between">
        <h1 className="header__logo m-0 font-thin leading-none">
          <Link className="hover:no-underline" to="/">
            Jonny <span className="text-white font-semibold">Acevedo</span>
          </Link>
        </h1>
        <img
          src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906075/portfolio/hamburguer-white_tyy3pk.svg"
          alt="Hamburguer Menu White"
          className="block md:hidden m-0"
          onClick={onMenuToggle}
        />
        <nav className="header__nav hidden md:block md:mt-1 ">
          <ul className="flex">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a href="#contact-me">Contact Me</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
