/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import "../styles/index.scss"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Typography from "typography"
import moragaTheme from "typography-theme-moraga"

import Header from "../components/header"
import Footer from "../components/footer"

const typography = new Typography(moragaTheme)
typography.injectStyles()

const Layout = props => {
  const { children, cssClass } = props

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className={cssClass + " layout-container"}>
          <Header />
          <main className="container max-w-screen-lg px-4">{children}</main>
          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
